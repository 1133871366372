import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CrCp } from '../Model/crcp';

@Injectable()
export class CrcpService {

  private crcpSource = new BehaviorSubject<CrCp>(null);
  currentCrcp = this.crcpSource.asObservable();

  constructor() { }

  changeCrcp(crcp: any) {
    this.crcpSource.next(crcp)
  }

}