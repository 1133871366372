<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>



<div class="mb-3 card">
 
  <div class="g-0 row">
    <div class="col-sm-12 col-md-6 col-xl-2">
      <button class="m-2 btn-icon-vertical btn btn-primary" (click)="goBack()">
        <i class="pe-7s-back btn-icon-wrapper"></i>Inapoi
      </button>
    </div>

    <div class="col-sm-12 col-md-6 col-xl-4">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-danger"></div>
          <i class="pe-7s-cash text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Total nerambursabil</div>
          <div class="widget-numbers"><span>{{ project.bugetNerambursabil | number : '1.0-0'}} lei</span></div>
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>

    <div class="col-sm-12 col-md-6 col-xl-4">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-success"></div>
          <i class="pe-7s-credit text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Plăți</div>
          <div class="widget-numbers text-success"><span>{{ project['sumaPlatita'] | number : '1.0-0' }} lei</span></div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-xl-2">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-primary"></div>
          <i class="pe-7s-graph2 text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Absorbtie</div>
          <div class="widget-numbers text-danger"><span>{{ project['progresFinanciar'] | number : '1.2-2'}} %</span></div>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-md-6 col-xl-3">
      <div class="card mb-3 widget-content">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-subheading mb-2">Cod SMIS</div>
              <div class="widget-heading">
                  <i class="pe-7s-keypad me-2 icon-gradient bg-happy-itmeo fa-2x" ></i>
                  <span style="vertical-align: super;">{{ project['codSmis']}}</span> 
              </div>
            </div>

          </div>
        </div>
  </div>
  <div class="col-sm-12 col-md-6 col-xl-3">
      <div class="card mb-3 widget-content">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-subheading mb-2">Etapa</div>
              <div class="widget-heading"><i class="pe-7s-way me-2 icon-gradient bg-love-kiss fa-2x"></i>
                  <span style="vertical-align: super;">{{ project['etapaCalculata']}}</span>
              </div>
            </div>
          </div>
        </div>
  </div>

  <div class="col-sm-12 col-md-6 col-xl-3">
    <div class="card mb-3 widget-content">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="widget-subheading mb-2">Stadiu</div>
            <div class="widget-heading"><i class="pe-7s-pin me-2 icon-gradient bg-success fa-2x"></i>
                <span style="vertical-align: super;">{{project['stadiuCalculat'] == 'In implementare' ? 'In curs de implementare' : project['stadiuCalculat']}}</span>
            </div>
          </div>
        </div>
      </div>
</div>
  <div class="col-sm-12 col-md-6 col-xl-3">
      <div class="card mb-3 widget-content">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-subheading mb-2"> Contract numarul</div>
              <div class="widget-heading"><i class="pe-7s-news-paper me-2 icon-gradient bg-night-sky fa-2x"></i>
                  <span style="vertical-align: super;">{{ project['numarContract']}}</span></div>
            </div>
          </div>
        </div>
  </div>
</div>

<div class="card-tabbed-header card-header-tab-animation">
  <div class="tabs-animated tabs-animated-line">
 <ul class="nav nav-tabs nav-fill">
        <li class="nav-item">
            <a class="nav-link" aria-selected="!this.isCrcpVisible" [ngClass]="{ 'active':this.isCrcpVisible == false}" data-toggle="tab"
              (click)="showBuget()">Sold proiect pe linii bugetare</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  aria-selected="this.isCrcpVisible" [ngClass]="{ 'active':this.isCrcpVisible == true}" data-toggle="tab"
            (click)="showCrcp()">Cereri de plata si de rambursare</a>
      </li>
    </ul>
  </div>
   
</div>


<!-- <div id="tabs" #tabs class="card-header card-header-tab">
  <ul class="nav nav-pills nav-fill m-2">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{ 'active':this.isCrcpVisible == false}" data-toggle="tab"
          (click)="showBuget()"><b>Original Sold proiect</b> pe linii bugetare</a>
    </li>
    <li class="nav-item">
      <a class="nav-link active" [ngClass]="{ 'active':this.isCrcpVisible == true}" (click)="showCrcp()"
          data-toggle="tab">Original Cereri de plata si rambursare</a>
    </li>
  </ul>
</div> -->



            



<div class="main-card mb-3 card">
  <div id="tabs" #tabs class="card-header card-header-tab">
    <!-- <ul class="nav nav-pills nav-fill m-2">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active':this.isCrcpVisible == false}" data-toggle="tab"
            (click)="showBuget()"><b>Sold proiect</b> pe linii bugetare</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" [ngClass]="{ 'active':this.isCrcpVisible == true}" (click)="showCrcp()"
            data-toggle="tab">Cereri de plata si rambursare</a>
      </li>
    </ul> -->
  
    <div class="tab-content">
      <div class="tab-pane" id="crcp" [ngClass]="{'active':this.isCrcpVisible == true}">
        <div class="table-responsive mt-3">
          <table class="align-middle mb-0 table table-borderless table-striped table-hover">
            <thead>
              <tr>
                <th class="text-center">Nr.</th>
                <th class="text-center">Tip cerere</th>
                <th class="text-center">Status</th>
                <th class="text-center">Suma solicitată</th>
                <th class="text-center">Suma avizată</th>
                <th class="text-center">Suma autorizată</th>
                <th class="text-center">Suma platită</th>
                <th class="text-center">Detalii</th>
              </tr>
            </thead>
            <tbody>

            <tr *ngFor="let cerere of crcp; index as i">
              <td class="text-center text-muted" scope="row">{{ cerere.numar }}</td>

              <td class="text-center text-muted" style="width: 80px;">{{ cerere.tip }} 
                
              </td>
              <!-- <td class="text-center">
                {{ cerere.nrCerereAferenta }}
                <div [ngClass]="{'badge rounded-pill bg-info':cerere.finala == 'DA'}">{{cerere.finala == 'DA'? 'FINALA': ''}}</div>
              </td> -->

              <td class="text-center">
                <!-- <div class="badge rounded-pill" [ngClass]="{'bg-danger':project?.['Status CR CP'] == 'Respinsa/Retrasa', 'bg-success':project?.['Status CR CP'] == 'Platita', 'bg-warning':project?.['Status CR CP'] == 'In verificare ADR'}">{{ project?.["Status CR CP"] }}</div> -->
                <div class="badge rounded-pill mr-2" [ngClass]="{'bg-danger':cerere?.status == 'Respinsa/Retrasa', 
                                                            'bg-success':cerere?.status == 'Platita', 
                                                            'bg-pink':cerere?.status == 'In verificare ADR',
                                                            'bg-primaryAdrGreen':cerere?.status == 'Autorizata AMPOR',
                                                            'bg-secondaryAdrDarkBlue':cerere?.status == 'In verificare la AMPOR',
                                                            'bg-primaryAdrPurple':cerere?.status == 'Suspendata',
                                                            'bg-info':cerere?.status == 'Cerere finalizata',
                                                            'bg-primaryAdrBlue':cerere?.status == 'In lucru'
                                                          }">{{ cerere.status }}</div>

                <div [ngClass]="{'badge rounded-pill bg-info ml-2':cerere.finala == 'DA'}">{{cerere.finala == 'DA'? 'FINALA': ''}}</div>
              </td>


              <!-- <td class="text-center text-muted" style="width: 80px;">{{ project["Cod SMIS"] }}</td> -->

              <td>
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left flex2">
                      <div class="widget-heading text-center">{{ cerere["sumaSolicitata"] | number : '1.2-2' }}</div>
                      <div class="widget-subheading text-center opacity-7">{{ !cerere.dataIntrarii ? '' : cerere.dataIntrarii.substr(0,4)+"-"+cerere.dataIntrarii.substr(4,2)+"-"+cerere.dataIntrarii.substr(6,2) | date : "dd/MM/yyyy" }}</div>
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left flex2">
                      <div class="widget-heading text-center">{{ cerere["sumaAvizata"] | number : '1.2-2' }}</div>
                      <div class="widget-subheading opacity-7 text-center">{{ !cerere.dataAvizariiOI ? '' : cerere.dataAvizariiOI.substr(0,4)+"-"+cerere.dataAvizariiOI.substr(4,2)+"-"+cerere.dataAvizariiOI.substr(6,2) | date : "dd/MM/yyyy" }}</div>
                    </div>
                  </div>
                </div>
              </td>

              <td>
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left flex2">
                        <div class="widget-heading text-center">{{ cerere["sumaAutorizata"] | number : '1.2-2' }}</div>
                        <div class="widget-subheading opacity-7 text-center">{{ !cerere.dataAutorizarii ? '' : cerere.dataAutorizarii.substr(0,4)+"-"+cerere.dataAutorizarii.substr(4,2)+"-"+cerere.dataAutorizarii.substr(6,2) | date : "dd/MM/yyyy" }}</div>
                      </div>
                    </div>
                  </div>
                </td>

                <td class="text-center">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left flex2">
                        <div class="widget-heading text-center">{{ cerere["sumaPlatitaFEDRBS"] | number : '1.2-2' }}</div>
                        <div class="widget-subheading opacity-7 text-center">{{ !cerere.dataPlatii ? '' :cerere.dataPlatii.substr(0,4)+"-"+cerere.dataPlatii.substr(4,2)+"-"+cerere.dataPlatii.substr(6,2) | date : "dd/MM/yyyy"}}</div>
                      </div>
                    </div>
                  </div>
                  <div [ngClass]="{'badge text-center bg-night-sky':cerere.nrCerereAferenta}">{{cerere.nrCerereAferenta? 'Aferentă CP '+cerere.nrCerereAferenta: ''}}</div>
                </td>

                <td class="text-center">

                  <div role="group" class="btn-group-sm btn-group">
                    <button class="mb-2 me-2 border-0 btn-transition btn btn-outline-primary" (click)="showActiuni(cerere)">
                      <i class="fa fa-eye bg-white"></i>
                    </button>
                  </div>


                  <!-- <div role="group" class="btn-group-sm btn-group">
                    <button class="btn-shadow btn btn-primary" (click)="showActiuni(cerere)" >Detalii</button>
                  </div> -->
                </td>
            </tr>
            </tbody>
          </table>
    </div>
  </div>
  <div class="tab-pane mb-3" id="buget" [ngClass]="{ 'active':this.isCrcpVisible == false}">

        <div class="table-responsive">
          <table class="align-middle mb-0 table table-borderless table-striped table-hover">
            <thead>
              <tr>
                <th class="text-center">Categorie cheltuiala</th>
                <th class="text-center">Subcategorie</th>
                <th class="text-center">Denumirea cheltuielii</th>
                <th class="text-center">Suma Eligibilă</th>
                <th class="text-center">Suma Cheltuită</th>
                <th class="text-center">Sold</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sold of sold?.solduri; index as i" [ngClass]="{'table-danger': sold?.sold == 0}">
               <td class="text-left">{{ sold?.cheltuiala?.categorie }}</td>
               <td class="text-left">{{ sold?.cheltuiala?.subcategorie }}</td>
               <td class="text-left">{{ sold?.cheltuiala?.denumire }}</td>
               <td class="text-right">{{ sold?.bugetEligibil | number : '1.2-2' }}</td>
               <td class="text-right">{{ sold?.autorizat | number : '1.2-2' }}</td>
               <td class="text-right widget-heading">{{ sold?.sold | number : '1.2-2' }}</td>
               </tr>
               <tr>
                <td colspan="2" ></td>
                <td class="text-center" >TOTAL</td>
                <td class="text-center">{{ totalEligibil| number : '1.2-2' }}</td>
                <td class="text-right">{{ totalAutorizat | number : '1.2-2' }}</td>
                <td class="text-right widget-heading">{{ totalSold | number : '1.2-2' }}</td>
               </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>

