import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgReduxModule} from '@angular-redux/store';
import {NgRedux, DevToolsExtension} from '@angular-redux/store';
import {rootReducer, ArchitectUIState} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';

import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';

// BOOTSTRAP COMPONENTS

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ChartsModule} from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// LAYOUT

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';

// HEADER

import {HeaderComponent} from './Layout/Components/header/header.component';
import {SearchBoxComponent} from './Layout/Components/header/elements/search-box/search-box.component';
import {UserBoxComponent} from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import {SidebarComponent} from './Layout/Components/sidebar/sidebar.component';
import {LogoComponent} from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import {FooterComponent} from './Layout/Components/footer/footer.component';

//App
// Dashboards
import { ProiecteComponent} from './Dashboard/proiecte.component';
import { CerereBugetComponent } from './CereriPlataSiBuget/cerere-buget.component';

//User paage components
import { ActivateAccountComponent } from './UserPages/activate//activate-account.component';
import { LoginComponent } from './UserPages/login/login.component';
import { RegisterComponent } from './UserPages/register/register.component';
import { ContulmeuComponent } from './UserPages/contulMeu/contulmeu.component';
import { ForgotPasswordComponent } from './UserPages/forgot-password/forgot-password.component';


//Services
import { AdrApiService } from './services/adr-api.service';
import { NotificationService } from './services/notification.service';
import { ProjectService } from './services/project.service';

//Notifications
import { ToastrModule } from 'ngx-toastr';

//locale
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { CustomvalidationService } from './services/customvalidation.service';
import { PasswordPatternDirective } from './directives/passwordPattern.directive';
import { MatchPasswordDirective } from './directives/matchPassword.directive';
import { ValidateCuiDirective } from './directives/validateCui.directive';
import { ActiuniComponent } from './Actiuni/actiuni.component';
import { CrcpService } from './services/crcp.service';
//import { AdrApiProjectsService } from './services/adr-api-projects.service';
import { CacheInterceptor } from 'src/app/interceptors/cache.interceptor';
import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ChangePasswordComponent } from './UserPages/change-password/change-password.component';
import { CrcpTabService } from './services/crcp-tab.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // Dashboards

    ProiecteComponent,

    CerereBugetComponent,

     // User Pages 
     ForgotPasswordComponent,
     LoginComponent,
     RegisterComponent,
     ContulmeuComponent,
     ActivateAccountComponent,
     PasswordPatternDirective,
     MatchPasswordDirective,
     ValidateCuiDirective,
     ActiuniComponent,
     SpinnerComponent,
     ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,

    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Charts

    ChartsModule,
    ToastrModule.forRoot({
      progressBar: true,
      preventDuplicates: true,
      positionClass: "toast-center-center"
    })
  ],
  providers: [
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr' // 'de' for Germany, 'fr' for France ...
    },
    // { provide: HTTP_INTERCEPTORS, 
    //   useClass: CacheInterceptor, 
    //   multi: true 
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
     },
     {
      provide: HTTP_INTERCEPTORS, useClass: 
      LoadingInterceptor, multi: true
     },
    ConfigActions,
    ProjectService,
    CrcpService,
    AdrApiService,
    NotificationService,
    CustomvalidationService,
    CrcpTabService
    //AdrApiProjectsService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
