import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CrCp } from '../Model/crcp';

@Injectable()
export class CrcpTabService {

  private crcpTab = new BehaviorSubject<boolean>(null);
  currentCrcpTab = this.crcpTab.asObservable();

  constructor() { }

  changeCrcpTab(crcpTab: boolean) {
    this.crcpTab.next(crcpTab)
  }
}
