import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';

import { ProiecteComponent } from './Dashboard/proiecte.component';
import { CerereBugetComponent } from './CereriPlataSiBuget/cerere-buget.component';
import { LoginComponent } from './UserPages/login/login.component';
import { ContulmeuComponent } from './UserPages/contulMeu/contulmeu.component';
import { RegisterComponent } from './UserPages/register/register.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ActivateAccountComponent } from './UserPages/activate/activate-account.component';
import { ForgotPasswordComponent } from './UserPages/forgot-password/forgot-password.component';
import { ActiuniComponent } from './Actiuni/actiuni.component';
import { ChangePasswordComponent } from './UserPages/change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {path: '', component: ProiecteComponent, data: {extraParameter: 'dashboardsMenu'}, canActivate: [AuthGuardService]},
      {path: 'cerereBuget', component: CerereBugetComponent, data: {extraParameter: 'cerereBugetMenu'}, canActivate: [AuthGuardService]},
      {path: 'actiuni', component: ActiuniComponent, data: {extraParameter: 'actiuniMenu'}, canActivate: [AuthGuardService]},
      {path: 'account', component: ContulmeuComponent, data: {extraParameter: 'accountMenu'}, canActivate: [AuthGuardService]},
    ]
  },
  { path: 'activate', component: ActivateAccountComponent, data: {extraParameter: ''}},
  { path: 'register', component: RegisterComponent, data: {extraParameter: ''}},
  { path: 'login', component: LoginComponent, data: {extraParameter: ''}},
  { path: 'forgot-password', component: ForgotPasswordComponent, data: {extraParameter: ''}},
  { path: 'change-password', component: ChangePasswordComponent, data: {extraParameter: ''}},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login'}
];




@NgModule({

//   imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled',
// anchorScrolling: 'enabled',
// relativeLinkResolution: 'legacy' })],

  imports: [
    RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
