<!-- <div class="h-100 bg-fundal bg-animation"> -->
<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-6">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-header">
            <div class="h5 modal-title">
              Activare cont beneficiar
              <h6 class="mt-1 mb-0 opacity-8"><span>Te rugăm sa îți alegi o parolă și să o confirmi pentru a activa contul tău.</span></h6></div>
          </div>
          <div class="modal-body">
            <div>
              <form class="form" #registerForm="ngForm" [appMatchPassword]="['passwordInput1', 'passwordInput2']"
              (ngSubmit)="registerForm.form.valid && onSubmit(registerForm)"  novalidate>
                <div  class="row">
                  <div class="col-md-12">
                    <fieldset class="mb-3" id="__BVID__132">
                      <div tabindex="-1" role="group" class="form-group">
                        <label for="cui" class="form-label">Cod CUI</label>
                        <input id="cui" name="cui" type="text" placeholder="Introdu codul CUI aici..." class="form-control" [ngModel] = "cui" disabled>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div  class="row">
                  <div class="col-md-12">
                    <fieldset class="mb-3" >
                        <div tabindex="-1" role="group" class="form-group">
                          <label for="passwordInput1" class="mb-3">Parola</label>
                          <input id="passwordInput1" name="passwordInput1" type="password" 
                          placeholder="Introdu parola aici..." class="form-control" 
                          [(ngModel)] = "passwordInput1" required 
                          #passInput1="ngModel" appPasswordPattern
                          >
                          <span class="text-danger"
                                *ngIf="(passInput1.touched || registerForm.submitted) && passInput1.errors?.required">
                                Parola este obligatorie
                            </span>
                            <span class="text-danger" *ngIf="passInput1.touched && passInput1.errors?.invalidPassword">
                                Parola trebuie să contină minim 8 caractere, cel putin o literă mare, o literă mică si un numar
                            </span>
                        </div>
                    </fieldset>
                  </div>
                </div>

                <div  class="row">
                  <div class="col-md-12">
                    <fieldset class="mb-3" >
                      <div tabindex="-1" role="group" class="form-group">
                        <label for="passwordInput2" class="mb-3">Confirmă Parola</label>
                        <input id="passwordInput2" name="passwordInput2" type="password" 
                        placeholder="Introdu din nou parola aici..." class="form-control" 
                        [(ngModel)] = "passwordInput2" required 
                        #passInput2="ngModel"
                        >
                        <span class="text-danger"
                                *ngIf="(passInput2.touched || registerForm.submitted) && passInput2.errors?.required">
                                Confirmarea parolei este obligatorie
                            </span>
                            <span class="text-danger"
                                *ngIf="passInput2.touched && passInput2.errors?.passwordMismatch">
                                Parola nu corespunde
                            </span>

                            <div class="divider"></div>
                            <h6 class="mb-0"><a routerLinkActive="active-item" [routerLink]="loginBeneficiar" class="text-primary">Mergi la pagina de login</a></h6>
                          
                      </div>
                    </fieldset>
                  </div>
                </div>


               
                <div class="modal-footer clearfix">
                  <div class="float-end">
                    <div class="form-group float-end">
                      <button type="submit" class="btn btn-secondaryAdrDarkBlue btn-lg" >Setează parola</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            
            
          </div>
          
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
         © ADR Vest - Agenția pentru Dezvoltare Regională Vest
      </div>
    </div>
  </div>
</div>
