import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Proiect } from '../Model/proiect';

@Injectable()
export class ProjectService {

  private projectSource = new BehaviorSubject<Proiect>(null);
  currentProject = this.projectSource.asObservable();

  constructor() { }

  changeProject(project: any) {
    this.projectSource.next(project)
  }

}