<div class="app-footer">
  <div class="app-footer__inner">
    <div class="app-footer-left">
      <a class="ps-2 text-white" href="https://www.adrvest.ro">© ADR Vest - Agenția pentru Dezvoltare Regională Vest </a>
    </div>
    <!-- <div class="app-footer-right">
      <a class="btn btn-sm btn-primary me-2" href="https://www.adrvest.ro">www.adrvest.ro</a>
    </div> -->

    
  </div>
</div>
