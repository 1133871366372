import { Component, OnInit } from '@angular/core';
import { faTh, faCheck, faTrash, faAngleDown, faAngleUp, faHourglassEnd, faMoneyBill, faCashRegister, faCreditCard, faBatteryHalf } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project.service';
import { Subscription } from 'rxjs';
import { AdrApiService } from '../services/adr-api.service';
import { CrCp } from '../Model/crcp';
import { NotificationService } from '../services/notification.service';
import { Actiune } from '../Model/actiune';
import { CrcpService } from '../services/crcp.service';
import { Location} from '@angular/common';
import { Sold } from '../Model/sold';
import { CrcpTabService } from '../services/crcp-tab.service';


@Component({
  selector: 'app-cerere-buget',
  templateUrl: './cerere-buget.component.html',
})
export class CerereBugetComponent implements OnInit {

  faHourglassEnd = faHourglassEnd;
  faMoneyBill = faMoneyBill;
  faPay = faCreditCard;
  faBattery = faBatteryHalf;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  isCrcpVisible = false;

  heading = 'Fisa proiect';
  subheading = '';
  icon = 'pe-7s-note2 icon-gradient bg-tempting-azure';

  public projectList : any[];
  public projectFinalizate : any[];
  public projectRespinse : any[];
  public projectRezerva : any[];
  public projectInCurs : any[];
  public nerambursabil: number = 0;
  public plati: number = 0;
  public nrFinalizate: number = 0;
  public nerambursabilInCurs: number = 0;
  public platiInCurs: number = 0;
  public gradAbsorbtie: number = 0;
  public actiuni: Actiune[];

  public project: any;
  projectSubscription: Subscription;
  crcpTabSubscription: Subscription;
  crcp: CrCp[]; //= (data as any).default;
  sold: Sold;
  currentCrcp: CrCp;
  searchTerm: string;

  totalEligibil: number;
  totalAutorizat: number;
  totalSold: number;

  constructor(private adrApiService: AdrApiService, 
    private router: Router,
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private crcpService: CrcpService,
    private _location: Location,
    private crcpTabService: CrcpTabService) {}

  ngOnInit() {
    this.projectSubscription = this.projectService.currentProject.subscribe(project => {
        this.project = project;
        this.heading = `${this.project.titluProiect}`;
        this.subheading = `Prioritatea de investitii: ${this.project.denumirePrioritateInvestitii}`;
  
        this.getCrCp();
        this.getSold();
    });

    this.crcpTabSubscription = this.crcpTabService.currentCrcpTab.subscribe( isCrcp => {
      this.isCrcpVisible = isCrcp || false;
    })
    console.log(JSON.stringify(this.crcp));
  }

  getCrCp(){
    this.adrApiService.getCrCp(this.project.id).subscribe(
      res => {
        this.crcp = res;
        this.crcp.map(
          (q) => {
            q.sumaAutorizata = q.sumaAutorizata | 0;
            q.sumaAvizata = q.sumaAvizata | 0;
            q.sumaPlatitaFEDRBS = q.sumaPlatitaFEDRBS | 0;
            q.nrClarificariTrimise = q.nrClarificariTrimise | 0;
            //q.status = (q.nrCerereAferenta || 0) <= 0 ? q.status : this.crcp.find(p => p.numar == q.nrCerereAferenta)?.status 
          }
        )
        console.log('lista de crcp:')
        console.log(this.crcp);

      }
    )
  }


  getSold(){
    this.adrApiService.getSold(this.project.id).subscribe(
      res => {
        this.sold = res;
        this.sold.solduri.map(
          (q) => {
            q.eligibilCerut = typeof q.eligibilCerut == "string" ? 0 : q.eligibilCerut; //parseInt(q.eligibilCerut.toString()) || 0;
            q.autorizat = typeof q.autorizat == "string" ? 0 : q.autorizat;  //parseInt(q.autorizat.toString()) || 0;
            q.sold = typeof q.sold == "string" ? 0 : q.sold; // parseInt(q.sold.toString()) || 0;
            q.sold = q.bugetEligibil - q.autorizat;
            q.idCategorie = parseInt(q.cheltuiala.categorie.split('-')[0]);
            q.idSubCategorie = parseInt(q.cheltuiala.subcategorie.split('-')[0]);
          }
        );
        this.sold.solduri.sort((a,b) => a.idCategorie - b.idCategorie || a.idSubCategorie - b.idSubCategorie);
        console.log('lista sold:')
        console.log(res);

        this.totalEligibil = Math.round(calculateSum(this.sold.solduri, 'bugetEligibil')*100)/100;
        this.totalAutorizat = Math.round(calculateSum(this.sold.solduri, 'autorizat')*100)/100;
        this.totalSold = Math.round(calculateSum(this.sold.solduri, 'sold')*100)/100;

        console.log('totalEligibil ' + this.totalEligibil);


      }
    )
  }

  ngOnDestroy() {
    this.projectSubscription.unsubscribe();
    this.crcpTabSubscription.unsubscribe();
  }

  showActiuni(cerere:CrCp){
    this.currentCrcp = cerere;
    this.crcpService.changeCrcp(this.currentCrcp);
    this.router.navigate(["actiuni"]); //, { relativeTo: this.route.root });
  }

  goBack(){
    this._location.back();
    //this.router.navigate([".."]);
  }

  showBuget(){
    this.isCrcpVisible = false;
  }

  showCrcp(){
    this.isCrcpVisible = true;
  }

}

function calculateSum(array, property1) {
  const total = array.reduce((accumulator, object) => {
    return accumulator + object[property1] || 0;
  }, 0);

  return total;
}
