import { Component, OnInit } from '@angular/core';
import { AdrApiService } from '../services/adr-api.service';
import { Router } from '@angular/router';
import { ProjectService } from '../services/project.service';
import { NotificationService } from '../services/notification.service';
import { Actiune } from '../Model/actiune';
import { CrcpService } from '../services/crcp.service';
import { CrCp } from '../Model/crcp';
import { Proiect } from '../Model/proiect';
import { Location} from '@angular/common';
import { CrcpTabService } from '../services/crcp-tab.service';

@Component({
  selector: 'app-actiuni',
  templateUrl: './actiuni.component.html',
})
export class ActiuniComponent implements OnInit {
  actiuni: Actiune[];
  crcpSubscription: any;
  projectSubscription: any;
  cerere: CrCp;
  heading: any;
  subheading: string;
  icon = 'pe-7s-cash icon-gradient bg-ripe-malin';
  actiuniBeneficiarList: any[];
  proiect:Proiect;

  constructor(private adrApiService: AdrApiService, 
    private router: Router,
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private crcpService: CrcpService,
    private crcpTabService: CrcpTabService,
    private _location: Location) { }

  ngOnInit(): void {

    this.actiuniBeneficiarList = [['sumaSolicitata', 'dataIntrarii', 'Cerere depusă în valoare de','danger'], 
                                  ['sumaAvizata', 'dataAvizariiOI', 'Suma avizată','primary'], 
                                  ['sumaAutorizata', 'dataAutorizarii', 'Suma autorizată', 'warning'], 
                                  ['sumaPlatitaFEDRBS', 'dataPlatii', 'Suma plătită', 'success']]

    this.projectSubscription = this.projectService.currentProject.subscribe(prj => {
      this.proiect = prj;
    });

    this.crcpSubscription = this.crcpService.currentCrcp.subscribe(crcp => {
      this.cerere = crcp;
      this.subheading = `Proiect: ${this.proiect.titluProiect}`;
      this.heading = `Cerere nr. ${this.cerere.numar}, cod SMIS: ${this.proiect.codSmis}`;
      console.log('nr cerere: '+this.cerere);

      this.adrApiService.getActiuni(this.cerere.id).subscribe(
        res => {
          this.actiuni = res.filter(q => q.nume.indexOf('Clarif') != -1)
                            .sort((a,b) => parseInt(a.dataFinalizareActiune) - parseInt(b.dataFinalizareActiune))
          this.actiuni.map( (q) => {
                                q.text = (q.nume.indexOf('primit') != -1) ? 'Raspuns la clarificari primit':'Clarificari trimise';
                                q.textColorType = (q.nume.indexOf('primit') != -1) ? 'success':'danger';
                              });
          console.log('lista actiuni: '+ JSON.stringify(this.actiuni));
        }
    )
  });

  }
  ngOnDestroy() {
    this.projectSubscription.unsubscribe();
    this.crcpSubscription.unsubscribe();
  }

  goBack(){
    this.crcpTabService.changeCrcpTab(true);
    this._location.back();
    //this.router.navigate([".."]);
  }

}
