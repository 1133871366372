<!-- <div class="h-100 bg-fundal-inverse bg-plum-plate-inverse bg-animation"> -->
<div class="h-100 bg-plum-plate_inverse bg-animation">
	<div class="row h-100 no-gutters">
		<div class="d-none d-lg-block col-lg-4">
			
		</div>
		<div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8 ">
			<div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
        <div class="h5 modal-title text-center">
          <img src="../../../../assets/images/logo_adrvest.png"/>
        </div>
        
        <div class="h5 modal-title text-left">
          <h4 class="h4 mt-2">
            <div>Bine ai venit,</div>
            <span>Autentifica-te in contul tau </span>
          </h4>
        </div>
				<h6 class="mt-3"> Nu ai cont? 
					<a class="text-primary" href="/register">Creeaza un cont</a>
				</h6>
				<div class="divider"></div>
				<div>
					<form novalidate="" class="ng-untouched ng-pristine ng-valid">
						<div class="row">
							
                            <div class="col-md-6 mb-3">
								<fieldset class="form-group">
									<div tabindex="-1" role="group">
										<label for="cui" class="mb-3">Cod CUI</label>
										<input id="cui" name="cui" type="text" placeholder="Introduceti codul CUI aici..." class="form-control" 
										autocomplete="username" [(ngModel)]="cui">
										</div>
										<div _nghost-serverapp-c138="" ng-reflect-messages="function(){return _this.getMes" class="ng-star-inserted">
										</div>
									</fieldset>
							</div>

							<div class="col-md-6">
									<fieldset class="form-group">
										<div tabindex="-1" role="group">
											<label for="beneficiarPassword" class="mb-3">Parola</label>
											<input id="beneficiarPassword" name="beneficiarPassword" type="password" placeholder="Introduceti parola aici..." class="form-control" 
											autocomplete="current-password" [(ngModel)] = "beneficiarPassword">
											</div>
											<div _nghost-serverapp-c138="" ng-reflect-messages="function(){return _this.getMes" class="ng-star-inserted">
											</div>
										</fieldset>
									</div>
							</div>
							<small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">
								Platforma dedicată beneficiarilor de proiecte europeene din regiunea Vest
							  </small>
							<div class="custom-control">
								</div>
									<div class="divider"></div>
									<div class="d-flex justify-content-end">
										<div class="ml-auto">
											<a class="btn-lg btn btn-link" routerLinkActive="active-item" [routerLink]="forgotPasswordBeneficiar" >Recuperează parola</a>
											<button type="button" class="btn btn-secondaryAdrDarkBlue btn-lg" (click)="loginBeneficiar()">Login</button>
										</div>
									</div>
								</form>
							</div>
						
						</div>
						
					</div>
				</div>
			</div>