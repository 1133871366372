<!-- <div class="h-100 bg-fundal"> -->
<div class="h-100 bg-plum-plate">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="modal-title">
              <h4 class="mt-2">
                <div>Bine ai venit,</div>
                <span>Durează numai <span class="text-success">câteva secunde</span> să îți creezi un cont</span>
              </h4>
            </h5>
            <div class="divider"></div>
            <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_" class="mb-3">
              <div>
                <input id="cui" type="cui" placeholder="Introduce codul CUI aici..." required="required"
                  aria-required="true" class="form-control" aria-describedby="exampleInputGroup1__BV_description_" [(ngModel)]="cui"> 
                <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">
                  Platformă dedicată beneficiarilor de proiecte europeene din regiunea Vest
                </small>
              </div>
            </div>
           

            <!-- <div class="custom-control custom-checkbox form-check">
              <input id="exampleCheck" type="checkbox" name="check" autocomplete="off" class="form-check-input" value="true">
              <label for="exampleCheck" class="form-label form-check-label">
                Accept our <a [routerLink]="">Terms and Conditions</a>.
              </label>
            </div> -->
            <div class="divider"></div>
            <h6 class="mb-0">
              Ai creat deja un cont?
              <a routerLinkActive="active-item" [routerLink]="loginBeneficiar" class="text-primary">Intră în cont</a>
              |
              <a routerLinkActive="active-item" [routerLink]="forgotPasswordBeneficiar" class="text-primary">Recuperează parola</a>
            </h6>
          </div>
          <div class="modal-footer d-block text-center">
            <button type="button" class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-secondaryAdrDarkBlue btn-lg" (click)="createAccount()">Creaza cont
            </button>
          </div>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
         © ADR Vest - Agenția pentru Dezvoltare Regională Vest
      </div>
    </div>
  </div>
</div>

