<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
     (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <!-- <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Meniu principal</div>
                </ng-template>
              </ngb-panel> -->

              <ngb-panel id="dashboardsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-menu"></i>
                    <span class="vsm-title">Meniu principal</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/" routerLinkActive="active-item"
                                               [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                        class="vsm-title">Proiecte</span></a>
                      </div>

                      <div class="vsm-item"><a routerLink="/account" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                        class="vsm-title">Profil</span></a>
                        </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
<!-- 
              <ngb-panel id="accountMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-rocket"></i>
                    <span class="vsm-title">Profil</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/account" routerLinkActive="active-item"
                                               [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                        class="vsm-title">Profilul meu</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->

              <!-- <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Setari</div>
                  <a routerLink="/account" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">Profil</span>
                  </a>
                </ng-template>
              </ngb-panel>

              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Principal</div>
                  <a routerLink="/" routerLinkActive="active-item" class="vsm-link">
                    <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">Proiecte</span>
                  </a>
                </ng-template>
              </ngb-panel> -->
      
            </ngb-accordion> 
            
            <!-- <div class="vsm-header">Charts</div>
            <div class="vsm-item">
              <a routerLink="/" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-menu"></i>
                    <span class="vsm-title">Lista proiecte</span>
              </a>
            </div>

            <div class="vsm-item">
              <a routerLink="/account" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">Profil</span>
              </a>
            </div> -->


          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
