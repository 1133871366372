<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="mb-3 card">
 
  <div class="g-0 row">

    <div class="col-sm-12 col-md-6 col-xl-3">
      <button class="m-2 btn-icon-vertical btn btn-primary" (click)="goBack()">
        <i class="pe-7s-back btn-icon-wrapper"></i>Inapoi
      </button>
    </div>


    <div class="col-sm-12 col-md-6 col-xl-3">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-primaryAdrPurple"></div>
          <i class="pe-7s-ticket text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Cerere nr.</div>
          <div class="widget-numbers text-primaryAdrPurple"><span>{{cerere.numar}}</span></div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-xl-3">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-danger"></div>
          <i class="pe-7s-shuffle text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Tipul cererii</div>
          <div class="widget-numbers"><span>{{cerere.tip}}</span></div>
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-12 col-md-6 col-xl-3">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-success"></div>
          <i class="pe-7s-credit text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Status</div>
          <div class="widget-numbers text-success"><span>{{cerere.status}}</span></div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="row">

  <div class="col-md-6"> 
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Informatii generale</h5>
        <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
          <div *ngFor="let tipActiune of actiuniBeneficiarList">

            <div *ngIf="cerere[tipActiune[1]]" class="vertical-timeline-item dot-{{tipActiune[3]}} vertical-timeline-element">
              <div>
                <span class="vertical-timeline-element-icon bounce-in"></span>
                <div class="vertical-timeline-element-content bounce-in">
                  <span class=text-{{tipActiune[3]}}>{{ !cerere[tipActiune[1]] ? '' : cerere[tipActiune[1]].substr(0,4)+"-"+cerere[tipActiune[1]].substr(4,2)+"-"+cerere[tipActiune[1]].substr(6,2) | date : "dd/MM/yyyy" }}</span>
                  <h4 class="timeline-title"> 
                    <span>{{tipActiune[2]}} </span>
                    <span class=text-{{tipActiune[3]}}>{{cerere[tipActiune[0]] | number : '1.2-2'}}</span> lei
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6" *ngIf="cerere.nrClarificariTrimise > 0">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">{{cerere.nrClarificariTrimise}} clarificari trimise</h5>
        <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
          <div *ngFor="let actiune of actiuni">

            <div *ngIf="actiune.dataFinalizareActiune" class="vertical-timeline-item dot-{{actiune.textColorType}} vertical-timeline-element">
              <div>
                <span class="vertical-timeline-element-icon bounce-in"></span>
                <div class="vertical-timeline-element-content bounce-in">
                  <span class=text-{{actiune.textColorType}}>{{ !actiune.dataFinalizareActiune ? '' : actiune.dataFinalizareActiune.substr(0,4)+"-"+actiune.dataFinalizareActiune.substr(4,2)+"-"+actiune.dataFinalizareActiune.substr(6,2) | date : "dd/MM/yyyy" }}</span>
                  <h4 class="timeline-title">{{actiune.text}}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="col-md-6">

    <div class="row">

      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-success border-success">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>{{cerere.sumaSolicitata}} lei</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">Suma solicitată</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-primary border-primary">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>{{cerere.sumaAvizata}} lei</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">Suma avizata</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-warning border-warning">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>{{cerere.sumaAutorizata}} lei</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">Suma autorizată</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow-danger border-danger">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content pt-3 ps-3 pb-1">
              <div class="widget-chart-flex">
                <div class="widget-numbers">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">$</small>
                      <span>{{cerere.sumaPlatitaFEDRBS}} lei</span></div>
                  </div>
                </div>
              </div>
              <h6 class="widget-subheading mb-0 opacity-5">Suma platită</h6>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div> -->


</div>