import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Proiect } from "../Model/proiect";
import { Observable } from 'rxjs';
import { CrCp } from "../Model/crcp";
import { Actiune } from "../Model/actiune";
import { Beneficiar } from "../Model/beneficiar";
import { Sold } from "../Model/sold";
import { environment } from "src/environments/environment";

@Injectable()
export class AdrApiService {
  loading: boolean;
  userHdl: any;
  isLoggedIn = false;
  token = undefined;
  beneficiar: Beneficiar;

  sDsURI = environment.apiUrl;
  //headers = new HttpHeaders({'Content-Type': 'application/json'});


  constructor(private http: HttpClient) {
    this.loading = false;
  }


  public register(cui: string) {
    let headers = new HttpHeaders({'client-url': environment.clientUrl,
                                  'redirect-endpoint': 'activate'})
    return this.http.post(`${this.sDsURI}/beneficiari/register`, JSON.stringify({"cui": cui}),  { headers: headers })
    .pipe(
        map(res => {
          console.log('register response : '+res);
          return res;
        }));
  }

  public activate( password: string, confirmPassword: string, tokenToPass: string) {
    let headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + tokenToPass,
                                    'client-url': environment.clientUrl,
                                    'redirect-endpoint': 'login'});
    const body = {
        "password": password,
        "confirmPassword": confirmPassword,
        "token":tokenToPass
    }

    return this.http.post(`${this.sDsURI}/beneficiari/activate`, JSON.stringify(body), {headers: headers}).pipe(
        map(res => {
          return res;
        }));
  }

  public login(cui: string, password: string) {
    return this.http.post(`${this.sDsURI}/beneficiari/login`, JSON.stringify({'cui': cui, 'password': password})).pipe(
        map(res => {
          this.token = res['token'];
          localStorage.setItem('token', res['token']);
          this.isLoggedIn = true;
          return res;
        }));
  }

  public signOut(){
    // const headers = new HttpHeaders({'Content-Type': 'application/json',
    //                                 'Authorization': 'Bearer ' + this.token});
    return this.http.post(`${this.sDsURI}/beneficiari/logout`, null).pipe(
      map(res => {
        this.token = undefined;
        localStorage.removeItem('token')
        this.isLoggedIn = false;
        return res;
      }));
  }

  public getProjects(reset: boolean): Observable<Proiect[]>{
    const headers = new HttpHeaders({'Reset': reset.toString()});

    return this.http.get<Proiect[]>(`${this.sDsURI}/beneficiari/proiecte`, { headers: headers }).pipe(
      map( res=> {
        return res;
      })
    )
  }

  public getCrCp(projectId: number){
    // const headers = new HttpHeaders({'Content-Type': 'application/json',
    // 'Authorization': 'Bearer ' + this.token});

    const url = `${this.sDsURI}/beneficiari/cereri?projectId=${projectId}`;

    return this.http.get<CrCp[]>(url).pipe(
      map( res=> {
        console.log(JSON.stringify(res));
        return res;
      })
    )
  }

  public getSold(projectId: number){
    // const headers = new HttpHeaders({'Content-Type': 'application/json',
    // 'Authorization': 'Bearer ' + this.token});

    const url = `${this.sDsURI}/beneficiari/sold?projectId=${projectId}`;

    return this.http.get<Sold>(url).pipe(
      map( res=> {
        return res;
      })
    )
  }

  public getActiuni(cerereId: string){
    // const headers = new HttpHeaders({'Content-Type': 'application/json',
    // 'Authorization': 'Bearer ' + this.token});

    const url = `${this.sDsURI}/beneficiari/actiuni?cerereId=${cerereId}`;

    return this.http.get<Actiune[]>(url).pipe(
      map( res=> {
        return res;
      })
    )
  }

  public getBeneficiarData(reset){
    const headers = new HttpHeaders({'Reset': reset.toString()});
    const url = `${this.sDsURI}/beneficiari`;

    return this.http.get<Beneficiar>(url, {headers: headers}).pipe(
      map( res=> {
        return res;
      })
    )
  }


  public requestChangePassword(cui: string) {
    let headers = new HttpHeaders({'client-url': environment.clientUrl,
                                  'redirect-endpoint': 'change-password'})
    return this.http.post(`${this.sDsURI}/beneficiari/request-change-password`, JSON.stringify({"cui": cui}),  { headers: headers })
    .pipe(
        map(res => {
          console.log('request-change-password resp : '+res);
          return res;
        }));
  }


  public changePassword(password: string, tokenToPass: string) {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + tokenToPass,
                                  'client-url': environment.clientUrl,
                                  'redirect-endpoint': 'login'})
    return this.http.post(`${this.sDsURI}/beneficiari/change-password`, JSON.stringify({"password": password}),  { headers: headers })
    .pipe(
        map(res => {
          console.log('request-change-password resp : '+res);
          return res;
        }));
  }

}
