import { Component, OnInit } from '@angular/core';
import { AdrApiService } from 'src/app/services/adr-api.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: []
})
export class ForgotPasswordComponent implements OnInit {

  cui: string = '';
  loginBeneficiar = '/login';
  
  constructor(private adrApiService: AdrApiService,
              private notificationService: NotificationService) { }

  ngOnInit() {
  }

  changePasswordRequest(){
    this.adrApiService.requestChangePassword(this.cui).subscribe(
      res => {
        this.notificationService.showSuccess(`${res['message']}: ${res['email']}`,'Succes');
      }
    )
  }
  

}
