import {Component, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../../../theme-options';
import { Router } from '@angular/router';
import { AdrApiService } from 'src/app/services/adr-api.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  constructor(public globals: ThemeOptions, 
              private router:Router,
              private adrApiService: AdrApiService,
              private notificationService: NotificationService ) {
  }

  ngOnInit() {
  }

  goToProfilePage(){
    this.router.navigate(['/account']);
  }

  signOut(){
    this.adrApiService.signOut().subscribe(
      res => {
        console.log(res['message']);
        //this.notificationService.showSuccess(res['message'], 'Logout');
      }
    );
    this.router.navigate(['/login']);
  }

}
