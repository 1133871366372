<!-- <div class="h-100 bg-fundal bg-animation"> -->
<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-6">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-header">
            <div class="h5 modal-title">
              Ai uitat parola?
              <h6 class="mt-1 mb-0 opacity-8"><span>O poți recupera folosind acest formular.</span></h6></div>
          </div>
          <div class="modal-body">
            <div>
              <form>
                <div  class="row">
                  <div class="col-md-12">
                    <fieldset class="mb-3" id="__BVID__132">
                      <div tabindex="-1" role="group">
                        <label for="cui" class="form-label">Cod CUI</label>
                        <input id="cui" name="cui" type="text" placeholder="Introdu codul CUI aici..." class="form-control" [(ngModel)]="cui">
                        </div>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
            <div class="divider"></div>
            <h6 class="mb-0"><a routerLinkActive="active-item" [routerLink]="loginBeneficiar" class="text-primary">Intră în cont</a></h6></div>
          <div class="modal-footer clearfix">
            <div class="float-end">
              <button type="button" class="btn btn-secondaryAdrDarkBlue btn-lg" (click)="changePasswordRequest()">Recuperează parola</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
         © ADR Vest - Agenția pentru Dezvoltare Regională Vest
      </div>
    </div>
  </div>
</div>
