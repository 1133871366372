<div class="d-flex">
  <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <img width="300" src="./assets/images/logo_ADR_desktop-1b85c11b.svg" alt="" class="p-3">
          <div class="btn-group" ngbDropdown placement="bottom-right" ml-3>
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle ml-10" >
     
                  <img width="27" class="rounded" src="./assets/images/avatars/user.png" alt="">
          
                </div>
              </span>
            </button>
            <div ngbDropdownMenu class="btn-secondaryAdrBlue">
              <!-- <button class="dropdown-item" (click)="goToProfilePage()">Profil</button>
              <div class="dropdown-divider"></div> -->
              <button class="dropdown-item" (click)="signOut()">Log-out</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

