import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdrApiService } from '../../services/adr-api.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styles: []
})
export class ActivateAccountComponent implements OnInit {

  loginBeneficiar = '/login';
  cui = '';
  passwordInput1 = '';
  passwordInput2 = '';
  token = '';
  
  constructor(private adrApiService: AdrApiService,
            private notificationService: NotificationService,
            private route: ActivatedRoute) { }

  ngOnInit() {
    this.cui = this.route.snapshot.queryParams['cui'] || '';
    this.token = this.route.snapshot.queryParams['token'] || '';
    console.log(this.cui);
    console.log(this.token);
  }

  onSubmit(f: NgForm){
    console.log(this.passwordInput1);
    console.log(this.passwordInput2);
    console.log(this.token);
    
    this.adrApiService.activate(this.passwordInput1, this.passwordInput2, this.token).subscribe(
      res => {
        console.log(JSON.stringify(res));
        this.notificationService.showSuccess(`${res["message"]}: ${res["email"]}`,'Succes');
      }
    )
  }
  

}
