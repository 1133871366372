import { Component, OnInit } from '@angular/core';
import { AdrApiService } from '../../services/adr-api.service';
import { NavigationExtras, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  forgotPasswordBeneficiar = '/forgot-password';
  cui = '';
  beneficiarPassword = '';

  constructor(private adrApiService: AdrApiService, private router: Router) { }

  ngOnInit() {

  }

  loginBeneficiar(){
    this.adrApiService.login(this.cui, this.beneficiarPassword).subscribe(
      res => {
        console.log(JSON.stringify(res));

        this.adrApiService.getBeneficiarData(true).subscribe(
          result => {
            localStorage.setItem('beneficiarName', result['solicitant']);
          }
        );

        this.adrApiService.getProjects(true).subscribe(
          res => {
            this.router.navigate(['/']);
          }
        );

        
      }
    )
  }

}
