<div *ngIf="this.loader.getLoading()" class="cssload-container">
    <!-- <div class="cssload-speeding-wheel"></div> -->
        <div class="loader">
            <div class="loader-inner ball-spin-fade-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
        </div>
</div>
