<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="main-card mb-5 card">
  <div class="card-body"><h5 class="card-title">Informatii generale</h5>
    <form>
      <div class="row">
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="exampleEmail11" class="form-label">Cod unic de înregistrare (CUI)</label>
            <input name="email" id="CUISolicitant" type="email" class="form-control" [readonly]="!infoEditable" [value]="beneficiar?.cui">
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="examplePassword11" class="form-label">Solicitant</label>
            <input name="password" id="numeSolicitant" class="form-control" [readonly]="!infoEditable" [value]="beneficiar?.solicitant"></div>
        </div>
      </div>
      <div class="position-relative mb-3">
        <label for="exampleAddress" class="form-label">Adresa</label>
        <input name="address" id="adresaSolicitant" type="text" class="form-control" [readonly]="!infoEditable" [value]="beneficiar?.adresa">
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="exampleCity" class="form-label">Localitate</label>
            <input name="city" id="localitateSolicitant" type="text" class="form-control" [readonly]="!infoEditable" [value]="beneficiar?.localitate">
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative mb-3">
            <label for="exampleState" class="form-label">Judet</label>
            <input name="state" id="judetSolicitant" type="text" class="form-control" [readonly]="!infoEditable" [value]="beneficiar?.judet">
          </div>
        </div>
      </div>

      <!-- <button class="mt-2 btn btn-primary">Modifica</button> -->
    </form>
  </div>
</div>


<div class="main-card mb-3 card">
    <div class="card-body"><h5 class="card-title">Reprezentant legal</h5>
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="position-relative mb-3">
              <label for="exampleEmail11" class="form-label">Nume</label>
              <input name="email" id="numeResponsabil" type="email" class="form-control" [readonly]="!infoResponsableEditable" [value]="beneficiar?.reprezentantLegal?.nume || ''">
            </div>
          </div>
          <div class="col-md-6">
            <div class="position-relative mb-3">
              <label for="examplePassword11" class="form-label">Functie</label>
              <input name="password" id="functieResponsabil" class="form-control" [readonly]="!infoResponsableEditable" [value]="beneficiar?.reprezentantLegal?.functie || ''"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="position-relative mb-3">
              <label for="exampleCity" class="form-label">Telefon</label>
              <input name="city" id="telResponsabil" type="text" class="form-control" [readonly]="!infoResponsableEditable" [value]="beneficiar?.reprezentantLegal?.telefon || ''">
            </div>
          </div>
          <div class="col-md-6">
            <div class="position-relative mb-3">
              <label for="exampleAddress" class="form-label">Email</label>
              <input name="address" id="emailResponsabil" type="text" class="form-control" [readonly]="!infoResponsableEditable" [value]="beneficiar?.reprezentantLegal?.email  || ''">
            </div>
          </div>
        </div>

  
        <!-- <button class="mt-2 btn btn-primary">Modifica</button> -->
      </form>
    </div>
  </div>