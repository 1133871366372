import { Component, OnInit } from '@angular/core';
import {Color} from 'ng2-charts/ng2-charts';
import { faTh, faCheck, faTrash, faAngleDown, faAngleUp, faHourglassEnd, faMoneyBill, faCashRegister, faCreditCard, faBatteryHalf } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project.service';
import { Subscription } from 'rxjs';
import { AdrApiService } from '../services/adr-api.service';
import { Proiect } from '../Model/proiect';
import { NotificationService } from '../services/notification.service';
import { query } from '@angular/animations';
import { environment } from '../../environments/environment';
import { CrcpTabService } from '../services/crcp-tab.service';



@Component({
  selector: 'app-proiecte',
  templateUrl: './proiecte.component.html',
})
export class ProiecteComponent implements OnInit {

  faHourglassEnd = faHourglassEnd;
  faMoneyBill = faMoneyBill;
  faPay = faCreditCard;
  faBattery = faBatteryHalf;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  

  heading = 'Proiectele mele';
  subheading: string;
  icon = 'pe-7s-note2 icon-gradient bg-tempting-azure';


  public listaProiecte: Proiect[];
  public proiecteFinalizate: Proiect[];
  public proiecteRespinse: Proiect[];
  public proiecteRezerva: Proiect[];
  public proiecteInCurs: Proiect[];
  public proiecteContractSemnat: Proiect[];

  public projectList : any[];
  public projectFinalizate : any[];
  public projectRespinse : any[];
  public projectRezerva : any[];
  public projectInCurs : any[];
  public nerambursabil: number = 0;
  public plati: number = 0;
  public nrFinalizate: number = 0;
  public nerambursabilInCurs: number = 0;
  public platiInCurs: number = 0;
  public gradAbsorbtie: number = 0;

  public currentProject: any;
  subscription: Subscription;
  subscriptionProjects:Subscription;
  beneficiarName = '';

  constructor(private adrApiService: AdrApiService,
              private router: Router, 
              private projectService: ProjectService,
              private notificationService: NotificationService,
              private crcpTabService: CrcpTabService,
              private route: ActivatedRoute) {
                console.log(environment.production); // Logs the development environment
              }

  ngOnInit() {
    this.subscription = this.projectService.currentProject.subscribe(project => this.currentProject = project); 
    this.getProjects();
  }

  ngAfterContentInit(){
    this.subheading = localStorage.getItem('beneficiarName');
  }

  getProjects(){
    this.adrApiService.getProjects(false).subscribe(
      res => {
        console.log('lista de proiecte:')
        console.log(res);
        this.projectList = res;

        this.projectList.map((q) => {
          q.etapaCalculata = q.stadiuProiect.substring(0, 2) == "R/" ? `Evaluare ${q.stadiuProiect.substring(2)}` : q.etapaProiect == "Contract semnat" ? 'Implementare' : q.stadiuProiect == "Retras" ? 'Evaluare' : q.etapaProiect;
          q.stadiuCalculat = q.stadiuProiect == "C" ? "In implementare" : q.stadiuProiect.substring(0,2) == "R/" ? "Respins" : q.stadiuProiect;
          q.sumaPlatita = q.sumaPlatita | 0;
        });


       
        this.plati = calculateSum(this.projectList, 'sumaPlatita')/1000000;
        this.projectFinalizate = this.projectList.filter(item => item.stadiuProiect == "Finalizat")
        this.nrFinalizate = this.projectFinalizate.length;
        this.projectRespinse = this.projectList.filter(item => item.stadiuProiect.substring(0,1) == "R/")
        this.projectRezerva= this.projectList.filter(item => item.stadiuProiect == "Rezerva");
        this.projectInCurs = this.projectList.filter(item => item.stadiuProiect == "C")
        this.proiecteContractSemnat = this.projectList.filter(item => item.stadiuProiect == "C" || item.stadiuProiect == "Finalizat")
        this.nerambursabil = calculateSum(this.proiecteContractSemnat, 'bugetNerambursabil')/1000000; //proiecteContractSemnat

        this.nerambursabilInCurs = calculateSum(this.projectInCurs, 'bugetNerambursabil')/1000000;
        this.platiInCurs = calculateSum(this.projectInCurs, 'sumaPlatita')/1000000;

        //this.gradAbsorbtie = this.proiecteContractSemnat.length == 0 ? 0 : calculateSum(this.proiecteContractSemnat, 'progresFinanciar')/this.proiecteContractSemnat.length;
        this.gradAbsorbtie = 100*(this.plati/this.nerambursabil)
        //this.heading = this.projectList.length == 0 ? this.heading : this.projectList[0].Solicitant;

        this.projectList.sort((a,b) => a.codSmis - b.codSmis);

      }
      
    );


    function calculateSum(array, property) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[property] || 0;
      }, 0);
  
      return total;
    }
  }

  showProject(project:any){
    this.currentProject = project;
    this.projectService.changeProject(this.currentProject);
    this.crcpTabService.changeCrcpTab(false);
    this.router.navigate(["cerereBuget"]); 
    // console.log(`proiect/${project.id}`);
  }

ngOnDestroy() {
  this.subscription.unsubscribe();
  //this.subscriptionProjects.unsubscribe();
}


}
