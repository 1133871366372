import { Component, OnInit } from "@angular/core";
import { Beneficiar } from "src/app/Model/beneficiar";
import { AdrApiService } from "src/app/services/adr-api.service";

@Component({
    selector: 'app-contulmeu',
    templateUrl: './contulmeu.component.html',
    styles: []
  })
  export class ContulmeuComponent implements OnInit {
  
    public infoEditable = false;
    public infoResponsableEditable = false;
    public beneficiar: Beneficiar;

    heading = 'Contul meu';
    subheading = 'Aceste date nu sunt editabile. Pentru a modifica aceste informatii ne puteți contacta pe adresa de e-mail helpdesk@adrvest.ro.';
    icon = 'pe-7s-id icon-gradient bg-tempting-azure';

    constructor(private adrApiService : AdrApiService) { }
  
    ngOnInit() {
      this.adrApiService.getBeneficiarData(false).subscribe(
        res => {
          this.beneficiar =  res;
          console.log('beneficiarData: '+JSON.stringify(res));
        }
      )
    }
    
  }