import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");
    let request = req.clone();

    if (!req.headers.has('Authorization')) {
      request = request.clone({
        headers: req.headers.set('Authorization',
            'Bearer ' + token)
      });
    }
    

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    // setting the accept header
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
        catchError(err => {
            this.notificationService.showError(`A aparut o eroare: ${err.error.title} - ${err.error.detail}`, 'Eroare');   
            return of(err);
        }));
  }
}