import { Component, OnInit } from '@angular/core';
import { AdrApiService } from '../../services/adr-api.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent implements OnInit {

  loginBeneficiar = '/login';
  forgotPasswordBeneficiar = '/forgot-password'
  cui = '';

  constructor(private adrApiService: AdrApiService,
              private notificationService: NotificationService) {}

  ngOnInit() {
  }

  createAccount(){
    this.adrApiService.register(this.cui).subscribe(
      res => {
        console.log(JSON.stringify(res));
        this.notificationService.showSuccess(`${res['message']}: ${res['email']}`,'Succes');
      }
    )
  }

}
